<template>
  <Modal :options="{width: '50vw',close:true}" @close="$emit('close')">
    <div class="title">{{ !app.id ? 'Nueva' : 'Editar' }} aplicación</div>
    <div class="body">
      <div class="row form-group">
        <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
        <div class="col-sm-10"><input v-model="app_edit.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
      </div>
      <div v-if="app_edit.token" class="row form-group">
        <label for="token" class="col-form-label col-sm-2">Token</label>
        <div class="col-sm-10"><input :value="app_edit.token" type="text" name="token" id="token" class="form-control" readonly></div>
      </div>
      <div class="row form-group">
        <label for="estatus" class="col-form-label col-sm-2">Estatus</label>
        <div class="col-sm-10">
          <select v-mode="app_edit.estatus" name="estatus" id="estatus" class="form-control">
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Roles</label>
      </div>
      <div class="row form-group">
        <div class="col-sm-12">
          <ul class="bloq_roles">
            <li v-for="role in roles">
              <input v-model="app_edit.roles" :value="role.account_rol_id" type="checkbox" name="roles_sel[]" id="roles_sel[]">
              {{ role.nombre }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer text-right">
      <button class="btn btn-primary mr-2" @click="guardar_token"><i class='fa fa-save mr-sm-1'></i> Guardar</button>
      <button class="btn btn-outline-secondary" @click="$emit('close')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';

import apiAplicaciones from '@/apps/bpb_apps_admin/api/aplicaciones.api';
import apiClientes from '@/apps/bpb_apps_admin/api/clientes.api';

export default {
  components: {
    Modal
  },
  props: {
    app: {
      type: Object,
      required: true
    },
    aplicacion_id: {
      type: String,
      required: true
    },
    cliente_id: {
      type: String,
      required: true
    },
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      app_edit: {
        nombre: null,
        estatus: 'activo',
        roles: []
      },
      roles: []
    }
  },
  mounted() {
    if (this.app.id)
      this.app_edit = this.$helper.clone(this.app);

    this.cargar_roles();
  },
  methods: {
    async cargar_roles() {
      this.roles = (await apiAplicaciones.roles(this.aplicacion_id)).data;
    },
    async guardar_token() {
      let res;
      if (!this.app_edit.id)
        res = (await apiClientes.crear_aplicacion(this.aplicacion_id, this.cliente_id, this.app_edit)).data;
      else
        res = (await apiClientes.editar_aplicacion(this.aplicacion_id, this.cliente_id, this.app_edit.id, this.app_edit)).data;

      let roles = res.roles.map(rol => rol.account_rol_id);
      res.roles = roles;
      this.app_edit = res;

      this.$emit('update',1);
    }
  }
}
</script>

<style lang="scss" scoped>
.bloq_roles {
  margin: 0px;
  padding: 0px;
  columns: 3;

  li {
    list-style: none;
  }
}
</style>